import React, { FC, useEffect } from 'react';

const NotFoundPage: FC = () => {
  useEffect(() => {
    window.location.replace(`https://azarlive.com/`);
  }, []);
  return <></>;
};

export default NotFoundPage;
